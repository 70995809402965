<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <silver-coin-box
      :columns="columns"
      :rows="barcodeList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showList="ifAdmin()"
      :showDownload="true"
      :showSearch="false"
      :showToolbar="true"
      :showPagination="true"
      :showFilter="true"
      :pagination="pagination"
      listText="Upload Category Wise List"
      addText="Download OSA Report"
      downloadText="DownloadList"
      emptyText="No Data Found."
      @download="onDownload"
      @list="viewList"
      @add="onDownloadReport"
      :filterOptions="filterOptions"
    >
    </silver-coin-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import SilverCoinBox from '../../components/SilverCoinBox.vue';

export default {
  components: { SilverCoinBox },
  name: 'SilverCoinList',
  metaInfo() {
    return {
      title: 'Barcode Scanner',
      meta: [
        {
          name: 'barcode-scanner',
          content: `Download mis-barcode`
        }
      ]
    };
  },
  data() {
    return {
      title: 'OSA Report',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'EAN Code',
          class: { 'text-center': true },
          textKey: 'barcode',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Article Code',
          class: { 'text-center': true },
          textKey: 'articleCode',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Article Name',
          class: { 'text-center': true },
          textKey: 'articleName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'System Count',
          class: { 'text-center': true },
          textKey: 'systemCount',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Physical Count',
          class: { 'text-center': true },
          textKey: 'physicalCount',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Group',
          class: { 'text-center': true },
          textKey: 'group',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Category',
          class: { 'text-center': true },
          textKey: 'category',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          class: { 'text-center': true },
          textKey: 'submittedByName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Scanned By Name',
          class: { 'text-center': true },
          textKey: 'scannedByName',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Scanned By Designation',
          class: { 'text-center': true },
          textKey: 'scannedByDesignation',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Scanned By Department',
          class: { 'text-center': true },
          textKey: 'scannedByDepartment',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '8%'
        }
      ],
      searchItems: [],
      filterOptions: []
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listBarcode({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listBarcode({ query: this.$route.query });
    }
    // this.downloadSilver({ query: this.$route.query });
    this.listClass({});
  },
  beforeRouteUpdate(to, from, next) {
    this.listBarcode({ query: to.query });
    next();
  },
  methods: {
    ...mapActions('barcode', ['download', 'listBarcode', 'reportOSA', 'listClass']),
    onDownloadReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.reportOSA({ query: { ...this.$route.query } });
      } else {
        this.reportOSA({
          query: {
            ...this.$route.query,
            startdate: moment().startOf('week').add(2, 'days').format('YYYY-MM-DD'),
            enddate: moment().startOf('week').add(9, 'days').format('YYYY-MM-DD')
          }
        });
      }
    },
    onDownload() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    ifAdmin() {
      const isAdmin = this.user.roles.filter(role => role.name === 'ADMIN' || role.name === 'SUB_ADMIN');

      if (isAdmin.length) {
        return true;
      }
      return false;
    },
    viewList() {
      this.$router.push({ name: 'barcode-category-list' });
    }
  },
  computed: {
    ...mapState('barcode', [
      'loading',
      'baseUrl',
      'pagination',
      'downloadBarcodeList',
      'barcodeList',
      'report',
      'classList'
    ]),
    ...mapState('auth', ['user'])
  },
  watch: {
    classList(newValue) {
      this.filterOptions = [{ value: null, text: 'Select Filter' }];
      newValue.forEach(item => {
        this.filterOptions.push({ value: item.class, text: item.class });
      });
    },
    report(newValue) {
      if (newValue.length) {
        let csv = '';
        csv += `Report From '${newValue[0].reportFrom}'\n`;
        csv += `,,,,\n`;
        csv += `Region Wise Category Wise\n`;
        csv += `,,,,\n`;
        csv +=
          'Region,Group,Category,Total Article Count For Scanning,Article Scanned,Article Efficency %,System Stock Count,Physical Stock Count,Aticle Count Found Available,Availability % ';
        const regions = ['East', 'North', 'South'];
        regions.forEach(region => {
          const regionData = newValue.filter(row => row.region === region);
          const groups = _.uniq(regionData.map(row => row.group));
          groups.forEach(group => {
            const groupData = regionData.filter(row => row.group === group);
            const categories = _.uniq(groupData.map(row => row.category));
            categories.forEach(category => {
              const categoryData = groupData.filter(row => row.category === category);
              const totalArticleCountForScanning = categoryData.reduce((acc, row) => acc + row.articleToBeScanned, 0);
              const articleScanned = categoryData.reduce((acc, row) => acc + row.articleScanned, 0);
              const articleEfficency = ((articleScanned / totalArticleCountForScanning) * 100).toFixed(2) || 0;
              const systemStockCount = categoryData.reduce((acc, row) => acc + row.systemCount, 0);
              const physicalStockCount = categoryData.reduce((acc, row) => acc + row.physicalCount, 0);
              const aticleCountFoundAvailable = categoryData.reduce(
                (acc, row) => acc + row.articleCountFoundAvilable,
                0
              );
              const availability = ((aticleCountFoundAvailable / articleScanned) * 100).toFixed(2) || 0;
              csv += `\n${region},${group},${category},${totalArticleCountForScanning},${articleScanned},${articleEfficency},${systemStockCount.toFixed(
                0
              )},${physicalStockCount.toFixed(0)},${aticleCountFoundAvailable},${availability}`;
            });
          });
        });
        const indiaTotalArticleCountForScanning = newValue.reduce((acc, row) => acc + row.articleToBeScanned, 0);
        const indiaArticleScanned = newValue.reduce((acc, row) => acc + row.articleScanned, 0);
        const indiaArticleEfficency = ((indiaArticleScanned / indiaTotalArticleCountForScanning) * 100).toFixed(2) || 0;
        const indiaSystemStockCount = newValue.reduce((acc, row) => acc + row.systemCount, 0);
        const indiaPhysicalStockCount = newValue.reduce((acc, row) => acc + row.physicalCount, 0);
        const indiaAticleCountFoundAvailable = newValue.reduce((acc, row) => acc + row.articleCountFoundAvilable, 0);
        const indiaAvailability = ((indiaAticleCountFoundAvailable / indiaArticleScanned) * 100).toFixed(2) || 0;
        csv += `\nIndia,Total,Total,${indiaTotalArticleCountForScanning},${indiaArticleScanned},${indiaArticleEfficency},${indiaSystemStockCount.toFixed(
          0
        )},${indiaPhysicalStockCount.toFixed(0)},${indiaAticleCountFoundAvailable},${indiaAvailability}`;
        const groups = _.uniq(newValue.map(row => row.group));
        groups.forEach(group => {
          const groupData = newValue.filter(row => row.group === group);
          const totalArticleCountForScanning = groupData.reduce((acc, row) => acc + row.articleToBeScanned, 0);
          const articleScanned = groupData.reduce((acc, row) => acc + row.articleScanned, 0);
          const articleEfficency = ((articleScanned / totalArticleCountForScanning) * 100).toFixed(2) || 0;
          const systemStockCount = groupData.reduce((acc, row) => acc + row.systemCount, 0);
          const physicalStockCount = groupData.reduce((acc, row) => acc + row.physicalCount, 0);
          const aticleCountFoundAvailable = groupData.reduce((acc, row) => acc + row.articleCountFoundAvilable, 0);
          const availability = ((aticleCountFoundAvailable / articleScanned) * 100).toFixed(2) || 0;
          csv += `\nIndia,${group},Total,${totalArticleCountForScanning},${articleScanned},${articleEfficency},${systemStockCount.toFixed(
            0
          )},${physicalStockCount.toFixed(0)},${aticleCountFoundAvailable},${availability}`;
        });

        csv += `,,,,\n`;
        csv += `,,,,\n`;
        csv += `ASM Wise Category Wise\n`;
        csv += `,,,,\n`;
        csv +=
          'ASM,Region,Group,Category,Total Article Count For Scanning,Article Scanned,Article Efficency %,System Stock Count,Physical Stock Count,Aticle Count Found Available,Availability % ';
        const asms = _.uniq(newValue.map(row => row.ASMName));
        asms.forEach(asm => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          const regions = _.uniq(asmData.map(row => row.region));
          regions.forEach(region => {
            const regionData = asmData.filter(row => row.region === region);
            const groups = _.uniq(regionData.map(row => row.group));
            groups.forEach(group => {
              const groupData = regionData.filter(row => row.group === group);
              const categories = _.uniq(groupData.map(row => row.category));
              categories.forEach(category => {
                const categoryData = groupData.filter(row => row.category === category);
                const totalArticleCountForScanning = categoryData.reduce((acc, row) => acc + row.articleToBeScanned, 0);
                const articleScanned = categoryData.reduce((acc, row) => acc + row.articleScanned, 0);
                const articleEfficency = ((articleScanned / totalArticleCountForScanning) * 100).toFixed(2) || 0;
                const systemStockCount = categoryData.reduce((acc, row) => acc + row.systemCount, 0);
                const physicalStockCount = categoryData.reduce((acc, row) => acc + row.physicalCount, 0);
                const aticleCountFoundAvailable = categoryData.reduce(
                  (acc, row) => acc + row.articleCountFoundAvilable,
                  0
                );
                const availability = ((aticleCountFoundAvailable / articleScanned) * 100).toFixed(2) || 0;
                csv += `\n${asm},${region},${group},${category},${totalArticleCountForScanning},${articleScanned},${articleEfficency},${systemStockCount.toFixed(
                  0
                )},${physicalStockCount.toFixed(0)},${aticleCountFoundAvailable},${availability}`;
              });
            });
          });
        });
        const asmIndiaTotalArticleCountForScanning = newValue.reduce((acc, row) => acc + row.articleToBeScanned, 0);
        const asmIndiaArticleScanned = newValue.reduce((acc, row) => acc + row.articleScanned, 0);
        const asmIndiaArticleEfficency =
          ((asmIndiaArticleScanned / asmIndiaTotalArticleCountForScanning) * 100).toFixed(2) || 0;
        const asmIndiaSystemStockCount = newValue.reduce((acc, row) => acc + row.systemCount, 0);
        const asmIndiaPhysicalStockCount = newValue.reduce((acc, row) => acc + row.physicalCount, 0);
        const asmIndiaAticleCountFoundAvailable = newValue.reduce((acc, row) => acc + row.articleCountFoundAvilable, 0);
        const asmIndiaAvailability =
          ((asmIndiaAticleCountFoundAvailable / asmIndiaArticleScanned) * 100).toFixed(2) || 0;
        csv += `\nIndia,-,-,Total,${asmIndiaTotalArticleCountForScanning},${asmIndiaArticleScanned},${asmIndiaArticleEfficency},${asmIndiaSystemStockCount.toFixed(
          0
        )},${asmIndiaPhysicalStockCount.toFixed(0)},${asmIndiaAticleCountFoundAvailable},${asmIndiaAvailability}`;
        csv += `,,,,\n`;
        csv += `,,,,\n`;
        csv += `Store Wise Category Wise\n`;
        csv += `,,,,\n`;
        csv +=
          'Store Id,Store Name,ASM Name,Region,Group,Category,Total Article Count For Scanning,Article Scanned,Article Efficency %,System Stock Count,Physical Stock Count,Aticle Count Found Available,Availability % ';
        const storeIds = _.uniq(newValue.map(row => row.storeid));
        storeIds.forEach(storeid => {
          const storeData = newValue.filter(row => row.storeid === storeid);
          const regions = _.uniq(storeData.map(row => row.region));
          regions.forEach(region => {
            const regionData = storeData.filter(row => row.region === region);
            const groups = _.uniq(regionData.map(row => row.group));
            groups.forEach(group => {
              const groupData = regionData.filter(row => row.group === group);
              const categories = _.uniq(groupData.map(row => row.category));
              categories.forEach(category => {
                const categoryData = groupData.filter(row => row.category === category);
                const totalArticleCountForScanning = categoryData.reduce((acc, row) => acc + row.articleToBeScanned, 0);
                const articleScanned = categoryData.reduce((acc, row) => acc + row.articleScanned, 0);
                const articleEfficency = ((articleScanned / totalArticleCountForScanning) * 100).toFixed(2) || 0;
                const systemStockCount = categoryData.reduce((acc, row) => acc + row.systemCount, 0);
                const physicalStockCount = categoryData.reduce((acc, row) => acc + row.physicalCount, 0);
                const aticleCountFoundAvailable = categoryData.reduce(
                  (acc, row) => acc + row.articleCountFoundAvilable,
                  0
                );
                const availability = ((aticleCountFoundAvailable / articleScanned) * 100).toFixed(2) || 0;
                csv += `\n${storeid},${storeData[0].storeName},${
                  storeData[0].ASMName
                },${region},${group},${category},${totalArticleCountForScanning},${articleScanned},${articleEfficency},${systemStockCount.toFixed(
                  0
                )},${physicalStockCount.toFixed(0)},${aticleCountFoundAvailable},${availability}`;
              });
            });
          });
        });
        const storeIndiaTotalArticleCountForScanning = newValue.reduce((acc, row) => acc + row.articleToBeScanned, 0);
        const storeIndiaArticleScanned = newValue.reduce((acc, row) => acc + row.articleScanned, 0);
        const storeIndiaArticleEfficency =
          ((storeIndiaArticleScanned / storeIndiaTotalArticleCountForScanning) * 100).toFixed(2) || 0;
        const storeIndiaSystemStockCount = newValue.reduce((acc, row) => acc + row.systemCount, 0);
        const storeIndiaPhysicalStockCount = newValue.reduce((acc, row) => acc + row.physicalCount, 0);
        const storeIndiaAticleCountFoundAvailable = newValue.reduce(
          (acc, row) => acc + row.articleCountFoundAvilable,
          0
        );
        const storeIndiaAvailability =
          ((storeIndiaAticleCountFoundAvailable / storeIndiaArticleScanned) * 100).toFixed(2) || 0;
        csv += `\nIndia,-,-,-,-,Total,${storeIndiaTotalArticleCountForScanning},${storeIndiaArticleScanned},${storeIndiaArticleEfficency},${storeIndiaSystemStockCount.toFixed(
          0
        )},${storeIndiaPhysicalStockCount.toFixed(0)},${storeIndiaAticleCountFoundAvailable},${storeIndiaAvailability}`;
        csv += `,,,,\n`;
        csv += `,,,,\n`;
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'OSA Report.csv';
        anchor.click();
      }
    },
    downloadBarcodeList(newValue) {
      if (newValue.length) {
        let csv = `Row Num,EAN Code,Article Code,Article Name,System Count,Physical Count,Store Id,Group,Class,Submitted By,Scanned By Name, Scanned By Department, Scanned By Designation,Created At\n`;
        newValue.forEach((item, index) => {
          csv += `${index + 1},${item.barcode},${item.articleCode},${item.articleName},${item.systemCount},${
            item.physicalCount
          },${item.storeid},${item.group},${item.category},${item.submittedByName},${item.scannedByName},${
            item.scannedByDepartment
          },${item.scannedByDesignation},${item.createdAt}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Barcode Data.csv';
        anchor.click();
      }
    }
  }
};
</script>

<style></style>
