var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"page-title text-center"},[_vm._v("Log Complaint")]),_c('b-overlay',{attrs:{"show":_vm.busy,"rounded":"sm"}},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.show)?_c('b-form',{class:{
            'p-3 box-shadow2 rounded': !_vm.isDarkMode,
            'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
          },on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-2","label-for":"input-2","description":"We'll never share your email with anyone else."},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,2068650575)},[_c('b-form-input',{class:{
                    'box-shadow3': !_vm.isDarkMode,
                    'placeholder-light text-light bg-dark box-shadow3': _vm.isDarkMode
                  },attrs:{"id":"input-2","type":"email","required":"","placeholder":"Enter your email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-issue","label-for":"input-issue"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Issue "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,2914227738)},[_c('b-form-input',{class:{
                    'box-shadow3': !_vm.isDarkMode,
                    'placeholder-light text-light bg-dark box-shadow3': _vm.isDarkMode
                  },attrs:{"id":"input-issue","type":"text","required":"","placeholder":"Enter issue"},model:{value:(_vm.issue),callback:function ($$v) {_vm.issue=$$v},expression:"issue"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-4","label-for":"input-4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Details "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,465194881)},[_c('b-form-textarea',{staticClass:"box-shadow3",class:{
                    'box-shadow3': !_vm.isDarkMode,
                    'placeholder-light text-light bg-dark box-shadow3': _vm.isDarkMode
                  },attrs:{"id":"input-4","required":"","placeholder":"Enter details"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"group-products","label-for":"input-products"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Store Ids "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,791302370)},[_c('multiselect',{ref:"multiselect",class:{ 'box-shadow3': !_vm.isDarkMode, 'darkMode box-shadow3': _vm.isDarkMode },attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Select Store Ids","label":"text","track-by":"value","options":_vm.stores,"multiple":false,"taggable":true},on:{"tag":_vm.addTag},model:{value:(_vm.storeids),callback:function ($$v) {_vm.storeids=$$v},expression:"storeids"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-5","label-for":"input-5"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Upload File "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,1582163974)},[_c('input',{attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadFile($event)}}})])],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"2","sm":"12"}},[_c('b-button',{staticClass:"mb-2",attrs:{"type":"submit","variant":_vm.isDarkMode ? 'outline-primary' : 'primary'}},[_vm._v("Submit")])],1),_c('b-col',{attrs:{"lg":"1","sm":"12"}},[_c('b-button',{attrs:{"type":"reset","variant":_vm.isDarkMode ? 'outline-danger' : 'danger'}},[_vm._v("Reset")])],1),_c('b-col'),_c('b-col',[_c('b-img',{class:{ invert: _vm.isDarkMode },attrs:{"src":"https://www.attabot.in/wp-content/uploads/2020/10/attabot-logo.png","fluid":"","rounded":""}})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }